import * as styles from './consultants.module.scss';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';

import Button from '../components/button';
import Callout from '../components/v2/callout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../components/v2/footer';
import Header from '../components/v2/header';
import SEO from '../components/seo';
import Section from '../components/section';
import Slideout from '../components/slideout';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { graphql } from 'gatsby';

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};

const ConsultantsPage = ({ data }) => {
  const consultants = data.consultants.edges;
  const [results, setResults] = useState(consultants.filter((c) => c.active !== false));
  const [active, setActive] = useState();
  const [category] = useState();

  useEffect(() => {
    let arr = consultants.filter((c) => {
      return !category || c.category === category;
    });
    shuffleArray(arr);
    setResults(arr);
  }, [category, consultants]);

  return (
    <>
      <SEO
        title="Certified Consultants"
        description="Elevate your quiz marketing with Interact Certified Consultants. Expert guidance for strategy, content creation, and lead generation to maximize your online engagement and conversions."
      />
      <Header btnTheme="primary" />
      <main className={styles.consultants}>
        <Section theme="white" style={{ marginTop: `4.5rem` }}>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8 text-center">
              <h1 className="heading-xl color-black">Interact Certified Consultants</h1>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6 text-center">
              <h2
                className="text-body color-muted-foreground text-center"
                style={{ marginBottom: '0' }}>
                Find someone to build a quiz and marketing system for you. Hire an Interact
                Certified Consultant who can help you with a variety of services.
              </h2>
              <ul className={styles.checklist}>
                <li>
                  <span className={styles.check}>
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <p className="text-body color-black">Quiz strategy</p>
                </li>
                <li>
                  <span className={styles.check}>
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <p className="text-body color-black">Quiz content creation (writing the quiz)</p>
                </li>
                <li>
                  <span className={styles.check}>
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <p className="text-body color-black">Email follow-ups for quiz leads</p>
                </li>
                <li>
                  <span className={styles.check}>
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <p className="text-body color-black">Facebook ad strategy and copy</p>
                </li>
              </ul>
            </div>
          </div>
        </Section>
        <Section theme="grey">
          <div className="row">
            {results.map(({ node }, i) => {
              const { title, description, img } = node.frontmatter;
              return (
                <div
                  className="col-lg-4 col-md-6 text-center"
                  style={{ marginBottom: `1.5rem` }}
                  key={node.id}>
                  <div
                    className={styles.card}
                    onClick={() => setActive(node)}
                    onKeyPress={() => setActive(node)}
                    role="button"
                    tabIndex={i}>
                    <div className={styles.img}>
                      <GatsbyImage image={getImage(img)} alt={title} />
                    </div>
                    <div className={styles.content}>
                      <p className="heading-sm color-black">
                        <strong>{title}</strong>
                      </p>
                      <p className="text-body color-muted-foreground" style={{ fontSize: '14px' }}>
                        {description}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Section>
        <Callout />
        {active && (
          <Slideout className={styles.slideout} isOpen={true} onClose={() => setActive()}>
            <div className={styles.img}>
              <GatsbyImage image={getImage(active.frontmatter.img)} />
            </div>
            <div dangerouslySetInnerHTML={{ __html: active.html }} />
            <Button
              theme="primary"
              size="large"
              href={active.frontmatter.btn}
              style={{ marginTop: `1rem` }}>
              {active.frontmatter.btnLabel}
            </Button>
          </Slideout>
        )}
      </main>
      <Footer />
    </>
  );
};

export default ConsultantsPage;

export const query = graphql`
  query {
    consultants: allMarkdownRemark(filter: { frontmatter: { tags: { in: "consultant" } } }) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            description
            id
            btn
            btnLabel
            img {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 72, height: 72, quality: 100)
              }
            }
          }
        }
      }
    }
  }
`;
